<script setup lang="ts">
const { tenant } = useTenant()
const { backgroundColorClass } = useTheme()
</script>

<template>
  <div
    :class="[backgroundColorClass, tenant]"
    class="layout absolute flex min-h-screen w-full flex-col overflow-x-hidden"
  >
    <PublicHeader />
    <PublicCmsSidebarMenu />

    
    
    <Container class="grow px-4 pt-10">
      <slot />
    </Container>

    <FooterDefault />
    <TairoPanels />
  </div>
</template>
